import React, { useState, useEffect, useRef } from 'react'

// Images
import Product1 from '../images/products/product-1.png';
import Product2 from '../images/products/product-2.png';
import Product3 from '../images/products/product-3.png';
import Product4 from '../images/products/product-4.png';
import Product5 from '../images/products/product-5.png';
import Product6 from '../images/products/product-6.png';

const minHeight = 350;

const selectedIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" className="inline mx-1 h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
    <path d="M13 7H7v6h6V7z" />
    <path fillRule="evenodd" d="M7 2a1 1 0 012 0v1h2V2a1 1 0 112 0v1h2a2 2 0 012 2v2h1a1 1 0 110 2h-1v2h1a1 1 0 110 2h-1v2a2 2 0 01-2 2h-2v1a1 1 0 11-2 0v-1H9v1a1 1 0 11-2 0v-1H5a2 2 0 01-2-2v-2H2a1 1 0 110-2h1V9H2a1 1 0 010-2h1V5a2 2 0 012-2h2V2zM5 5h10v10H5V5z" clipRule="evenodd" />
  </svg>
)

const unselectedIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" className="inline mx-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
  </svg>
)

const products = [
  {
    image: Product1,
    name: "INVENSE INDUSTRIAL GATEWAYS",
    description: "Multiprotocol wired and/or wireless gateways and control units.",
    applications: [
      "Remote Terminal Units",
      "Network Gateways",
      "Smart Cities",
      "Petrol Pumps",
      "Vending Machines",
      "Real Time Localisation Anchors",
      "Data Logger",
      "Sensor Node",
      "Telematics"
    ]
  },
  {
    image: Product2,
    name: "INVENSE TELEMATICS CONTROL UNIT",
    description: "4G/LoRaWAN based vehicle telematics control unit for transport vehicles, passenger cars, tractors, dumpers, forklifts etc.",
    applications: [
      "Fleet Management",
      "Vehicle health monitoring",
      "driver behaviour analysis",
      "Vehicle Telematics",
      "Precision Tracking",
      "Predictive Maintenance"
    ]
  },
  {
    image: Product3,
    name: "INVENSE SENSOR NODES",
    description: "Battery operated customizable sensor tags and real time localization modules. Supports BLE, UWB, SubGhz, LoRaWAN and custom RF protocols.",
    applications: [
      "Precision Asset Tracking",
      "Wireless Beacons",
      "Condition Monitoring",
      "Wireless Sensor Network",
      "Supply Chain Management",
      "Warehouse Management"
    ]
  },
  {
    image: Product4,
    name: "CUSTOMIZED HARDWARE",
    description: "Based on customer constraints and requirements, we can provide customized hardware solutions which can be easily integrated and deployed into the Invense ecosystem.",
    applications: []
  },
  {
    image: Product5,
    name: "INVENSE IoT Network Stack",
    description: "Confused on which IoT hardware and protocols to be used? Using our network stack, acquire and send data to devices, and run FOTA updates with just few customizable APIs without changing your existing architecture. Reduce your time to market significantly.",
    applications: []
  },
  {
    image: Product6,
    name: "INVENSE SOFTWARE STACK",
    description: "Customizable UI/UX, canned reports, advanced data processing algorithms, descriptive and diagnostic data analytics, control room",
    applications: []
  }
]

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default function ProductSlideShow() {
    const [slideNumber, setSlideNumber] = useState(0);

    const numberOfProducts = products.length;

    useInterval(() => {
      setSlideNumber((slideNumber + 1) % numberOfProducts);
    }, 3000);
    const currentProduct = products[slideNumber];

    return (
      <div className="">
        <div className="h-full bg-white flex" style={{ height: 500 }}>
          <div className="flex-1 bg-blue-200 flex items-center justify-center">
            <img src={currentProduct.image} className="h-60" alt={`${currentProduct.name}`} />
          </div>
          <div className="flex-1 p-4">
            <span className="text-xl text-gray-500 font-semibold uppercase">Products</span>
            <h3 className="mt-1 text-3xl text-primary font-bold uppercase">
              {currentProduct.name}
            </h3>
            <div className='py-2 text-gray-500'>{currentProduct.description}</div>
            {currentProduct.applications.length > 0 && <div className="font-bold uppercase mb-2">Applications:</div>}
            <div className='flex flex-wrap gap-2'>
              {
                currentProduct.applications.map(applicationText => {
                  return (
                    <div className='border p-1 rounded'>
                      {applicationText}
                    </div>
                  )
                })
              }
            </div>
            
          </div>
        </div>
        <div className="text-center mt-2 text-gray-400">
          <ul>
            {
              products.map((_product, index) => {
                return slideNumber === index ? selectedIcon : unselectedIcon;
              })
            }
          </ul>
        </div>
      </div>
    )
  }