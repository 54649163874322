import React from 'react'

//Helpers
import { classNames } from '../helpers/common.js';

export default function WhyInvenseCard(props) {
    const {
      icon,
      isSecondary,
      tileText,
      headText,
      mainText
    } = props;

    const textClasses = classNames(
      'absolute -inset-y-4 w-24 h-24 bg-white flex items-center justify-center text-3xl',
      isSecondary ? 'text-secondary' : 'text-primary'
    );

    const bgClasses = classNames(
      'ml-16 h-24 pl-8 flex-1 flex items-center',
      isSecondary ? 'bg-secondary' : 'bg-primary'
    );

    return (
      <div className="relative">
        <div className={textClasses}>
          {tileText}
        </div>
        <div className="absolute bottom-0 inset-x-16 overflow-hidden w-12">
          <div className="h-4 -rotate-45 transform origin-bottom-left bg-black opacity-25" style={{ '--tw-rotate': "-26deg" }}></div>
        </div>
        <div className={bgClasses}>
          <div className="p-4 flex gap-4 items-center">
            <img src={icon} className="h-12 w-12" /> 
            <div>
              <div className="text-sm text-white">{headText}</div>
              <div className="text-md text-white">{mainText}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }