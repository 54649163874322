import React, { useReducer } from 'react'

import configs from "../../config";

const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    message: ""
};

function reducer(state, action) {
  switch (action.type) {
    case 'update-firstname':
        return { ...state, firstname: action.value };
    
    case 'update-lastname':
        return { ...state, lastname: action.value };
    
    case 'update-email':
        return { ...state, email: action.value };
      
    case 'update-message':
        return { ...state, message: action.value };
    default:
      throw new Error();
  }
}

export default function ContactFrom() {
    const [state, dispatch] = useReducer(reducer, initialState);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "first_name": state.firstname,
            "last_name": state.lastname,
            "email": state.email,
            "message": state.message
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${configs.INVESE_HOST}/v1/lead`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
        
    }

    return (
        <form onSubmit={handleSubmit} className="py-10 grid grid-cols-2 gap-10">
            <div className="col-span-2 md:col-span-1">
                <label htmlFor="firstname" className="text-white">First Name</label>
                <input
                    required
                    className="bg-white bg-opacity-25 text-white appearance-none border w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="firstname"
                    type="text"
                    placeholder="John..."
                    value={state.firstname}
                    onChange={e => dispatch({ type: 'update-firstname', value: e.target.value })}
                />
            </div>
            <div className="col-span-2 md:col-span-1">
                <label htmlFor="lastname" className="text-white">Last Name</label>
                <input
                    className="col-span-2 md:col-span-1 bg-white bg-opacity-25 text-white appearance-none border w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="lastname"
                    type="text"
                    placeholder="Doe..."
                    value={state.lastname}
                    onChange={e => dispatch({ type: 'update-lastname', value: e.target.value })}
                />
            </div>
            <div className="col-span-2">
                <label htmlFor="email" className="text-white">Email</label>
                <input
                    required
                    className="bg-white bg-opacity-25 text-white appearance-none border w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="you@email.com"
                    value={state.email}
                    onChange={e => dispatch({ type: 'update-email', value: e.target.value })}
                />
            </div>
            <div className="col-span-2">
                <label htmlFor="message" className="text-white">Message</label>
                <textarea
                    className="bg-white bg-opacity-25 text-white appearance-none border w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="message"
                    type="textarea"
                    placeholder="Your message goes here..."
                    rows="6"
                    value={state.message}
                    onChange={e => dispatch({ type: 'update-message', value: e.target.value })}
                />    
            </div>
            <input
                className="col-span-2 md:col-span-1 bg-primary text-white appearance-none py-3 px-20 leading-tight focus:outline-none focus:shadow-outline"
                id="submit"
                type="submit"
                value="Send Message"
            />
        </form>
    )
  }