import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'

// Components
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx'
import ContactFrom from '../components/ContactFrom.jsx'
import WhyInvenseCard from '../components/WhyInvenseCard.jsx';
import ProductSlideShow from '../components/ProductSlideShow.jsx';
// images
import citybackground from '../images/hero-background.jpg';
import invenseStackBackground from '../images/how-invense-helps-background.png';
import invenseStackImage from '../images/how-invense-helps.png';
import whyChooseInvenseBackground from '../images/Why_choose_Invense_background.webp';
import whereToUseInvenseBackground from '../images/where-to-use-invense-background.png';
import whereToUseInvense from '../images/Where_to_use_Invense.png';
import contactUsBackgroundJpg from '../images/contact-us-background.jpg';
// import LogMaxImage from '../images/logmax.jpg';

import clockIcon from '../images/time.svg';
import maintainanceIcon from '../images/maintenance.svg';
import costIcon from '../images/cost.svg';
import applicationIcon from '../images/applications.svg';

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>INVENSE - Internet Of Things Telematic Solutions</title>
        <meta name="description" content="Invense is a IOT solutions platform. Where business can explore internet of things based solutions to thier business problems. We provide state of the art equipment and solutions to a wide range of industries." />
        <meta name="keyboards" content="IOT, IOT solutions, IOT consultants, engineering" />
      </Helmet>
      <main>
        <Header />
        <section
          id="hero"
          className="h-screen"
          style={{ backgroundImage: `url(${citybackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto pt-20 px-2 sm:px-6 lg:px-8 h-full flex items-center md:justify-between">
            <div className="relative flex-none">
              <div className="border-l-2 border-yellow-500 pl-2">
                <h3 className="text-2xl text-white">Power Your Business with</h3>
                <h1 className="block text-4xl text-yellow-500 font-bold">Invense</h1>
                <h1 className="block text-4xl text-yellow-500 font-bold">IoT Solutions</h1>
              </div>
              <div className="block mt-5 flex gap-2">
                <Link to="#how-helps" className="border border-white px-4 py-2 text-white">
                See Features
                </Link>
                <Link to="#contact-us" className="bg-primary px-4 py-2 text-white">
                  Rquest A Demo
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="ml-24 hidden md:block flex-1">
              <ProductSlideShow />
            </div>
          </div>
        </section>
        <section
          id="about-us"
          style={{ backgroundImage: `url(${invenseStackBackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-10">
            <h2 className="text-4xl text-primary font-light uppercase">
              About <span className="font-bold"> INVENSE </span>
            </h2>
            <div className="pt-8">
              <p className="py-2">Every business has its unique set of challenges and requirements when it comes to IoT usage and integration.</p>  
           
              <p className="py-2">We, at Invense Technologies, work towards solving these challenges by providing "tailor-made" IoT assets and analytical platforms, thus accelerating your digital and market requirements.</p>  
           
              <p className="py-2">Powered by our highly customizable, modular and ready to deploy IoT assets and platform stacks, users can rapidly develop, integrate and deploy their application and reducing their time to market and cost greatly.</p>  
            
              <p className="py-2">Invense Technologies provides all type of sensor nodes, gateways, edge, cloud and analytical platform. Users can scale based on their requirements.</p>  
            </div>
          </div>
        </section>
        <section
          id="how-helps"
          style={{ backgroundImage: `url(${invenseStackBackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-10">
            <h2 className="text-4xl text-primary font-light uppercase">
              How <span className="font-bold"> Invense Helps </span>
            </h2>
            <div className="pt-8 block md:flex gap-20">
              <div className="flex-1">
                  <img className="h-full" src={invenseStackImage} />
              </div>
              <div className="flex-1 flex flex-col justify-between">
                <p className="pb-2"> Invense IoT Solutions are broadly classified into four modular stacks  </p>
                <p className="py-2"> <strong>Invense Sensor Nodes</strong> are low powered, wireless sensor tags supporting BLE 5, ZIGBEE, LORAWAN and UWB and custom wireless protocols.  </p>
                <p className="py-2"> <strong>Invense Gateways And Control Units </strong> are multiprotocol data aggregator and standalone control units. They support LORAWAN, 2G, 4G, UWB, WIFI and BLE. Control Unit can be customized to perform additional tasks.  </p>
                <p className="py-2"> <strong>Invense Platform Stack</strong> handles data routing, encryption and security, Hardware management, and FOTA. Customers can access IoT data through simple API interface. </p>
                <p className="py-2"> <strong>Invense Software Stack</strong> includes end user application and provides diagnostic and descriptive data analytics, Customizable dashboard and UI/UX and advanced data processing and algorithms. </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="why-invense"
          style={{ backgroundImage: `url(${whyChooseInvenseBackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-10">
            <h2 className="text-4xl text-primary font-light uppercase">
              Why <span className="font-bold"> choose Invense </span>
            </h2>
            <div className="py-20 block grid md:grid-cols-2 gap-x-8 gap-y-12">
              <div className="flex-2">
                <WhyInvenseCard
                  icon={clockIcon}
                  tileText="62%"
                  headText="Rapid Prototyping & Deployment"
                  mainText="Reduction in time to market"
                />
              </div>

              <div className="flex-2">
                <WhyInvenseCard
                  isSecondary
                  icon={maintainanceIcon}
                  tileText="0"
                  headText="Maintenance Cost"
                  mainText="Secure FOTA for constant update & bug-fixes"
                />
              </div>

              <div className="flex-2">
                <WhyInvenseCard
                  isSecondary
                  icon={costIcon}
                  tileText="80%"
                  headText="Lower Infrastructure Cost"
                  mainText="Reduction in development cost"
                />
              </div>

              <div className="flex-2">
                <WhyInvenseCard
                  icon={applicationIcon}
                  tileText="50+"
                  headText="Scalability & Cross-compatibility"
                  mainText="Applications supported across the IoT market"
                />
              </div>

            </div>
          </div>
        </section>
        <section
          id="where-invense"
          style={{ backgroundImage: `url(${whereToUseInvenseBackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-10">
            <h2 className="text-4xl text-primary font-light uppercase">
              Where <span className="font-bold"> to Use </span>
            </h2>
            <div className="py-10 flex justify-center">
              <img src={whereToUseInvense} className="w-half" />
            </div>
          </div>
        </section>
        <section
          id="contact-us"
          className="bg-blend-darken"
          style={{ backgroundImage: `url(${contactUsBackgroundJpg})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-10">
            <h2 className="text-4xl text-white font-light uppercase">
              Contact <span className="font-bold"> Us </span>
            </h2>
            <div className="">
              <ContactFrom />
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
